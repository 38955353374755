import config from '../config/config'

export const setPageTitle = (title) => {
  if (typeof document !== 'undefined')
    document.title = `${title ? title : `${config.appName}`}`
}

export const checkForRedirection = (navigate) => {
  const url = typeof window !== 'undefined' ? window.location.href : ''
  if (!url.includes('/admin')) {
    navigate('/404/')
  }
}
