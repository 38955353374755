import { getItem, setItem } from '../utils/storage'
const allThemes = require('../config/themes.json')
const THEME_KEY = 'magic_task_theme'
const defaultTheme = { id: 'clean-theme', name: 'Clean', type: 'micro' }

export const getCurrentTheme = () => {
  const { data, error } = getItem(THEME_KEY)

  if (error) return defaultTheme

  const index = allThemes.findIndex((theme) => theme.id === data)

  if (index === -1) return defaultTheme

  return allThemes[index]
}

export const getComponentThemeUrl = (fileName) => {
  const currentTheme = getCurrentTheme()

  // console.log('getComponentThemeUrl', fileName, currentTheme)

  if (currentTheme.type !== 'micro') return null

  return `styles/${currentTheme.id}/${fileName}`
}

export const getLayoutThemeUrl = (isSuperAdmin) => {
  if (isSuperAdmin) return defaultTheme.id

  const currentTheme = getCurrentTheme()
  return currentTheme.id
}

export const setTheme = (id) => {
  let { error } = setItem(THEME_KEY, id)
  return { error }
}
