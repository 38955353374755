import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./full-screen-loader.scss";

const FullScreenLoader = (props) => {
  const { title, hide } = props;


  if (hide) return null;

  return (
    <div className="full-screen-loader-con">
      <h4 className="title">{title}</h4>
      <div className="LoaderBalls">
        <div className="LoaderBalls__item"></div>
        <div className="LoaderBalls__item"></div>
        <div className="LoaderBalls__item"></div>
      </div>
    </div>
  );
};

export default FullScreenLoader;
