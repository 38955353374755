import React, { useEffect, useContext, useState, useLayoutEffect } from 'react'
import { setPageTitle } from '../../utils'
import { getLayoutThemeUrl, getCurrentTheme } from '../../utils/theme'
import FullScreenLoader from '../../components/StructureComponents/UI/FullScreenLoader'
import { GlobalProvider, GlobalContext } from '../../context/GlobalState'

export default function PageLayout({ children }) {
  return (
    <>
      <GlobalProvider>
        <LayoutWithoutContext>{children}</LayoutWithoutContext>
      </GlobalProvider>
    </>
  )
}

const LayoutWithoutContext = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [scrollLoading, setScrollLoading] = useState(true)
  const [hideLayout, setHideLayout] = useState(true)

  useEffect(() => {
    const layoutTheme = getLayoutThemeUrl()

    if (layoutTheme) {
      let link = document.createElement('link')
      let head = document.getElementsByTagName('head')[0]
      link.rel = 'stylesheet'
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.href = `/themes/${layoutTheme}/styles/main.css?v=03.9.2021`
      head.appendChild(link)

      link.onload = function () {
        setLoading(false)
        document.getElementById('___gatsby').classList.add('show')
      }
      setTimeout(() => {
        setLoading(false)
        document.getElementById('___gatsby').classList.add('show')
      }, 9500)
    }
  }, [])

  const handleSetScroll = () => {
    localStorage.setItem('scrollpos', window.scrollY)
  }
  useLayoutEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof document !== 'undefined' &&
      typeof localStorage !== 'undefined'
    ) {
      document.documentElement.style.scrollBehavior = 'auto'
      // setTimeout(() => {
      //   let scrollpos = localStorage.getItem('scrollpos')
      //   if (scrollpos) window.scrollTo(0, scrollpos)
      //   localStorage.removeItem('scrollpos')
      //   setHideLayout(false)
      //   document.documentElement.style.scrollBehavior = 'smooth'
      // }, 500)

      window.addEventListener('scroll', handleSetScroll)

      return () => {
        window.removeEventListener('scroll', handleSetScroll)
      }
    }
  }, [])

  const { isChangingTheme, setIsChaningTheme } = useContext(GlobalContext)

  // console.log('context is -->', isChangingTheme, setIsChaningTheme)

  useEffect(() => {
    setIsChaningTheme(loading)
  }, [loading])

  return (
    <>
      <div
        style={{ opacity: loading ? 0 : 1, minHeight: '100vh' }}
        className="user-layout-container"
        id="user-layout-container"
      >
        <FullScreenLoader
          title={`Loading ${getCurrentTheme().name}...`}
          hide={!isChangingTheme}
        />
        <div id="main-page-container-user" className="main-page-container">
          {children}
        </div>
      </div>
      {/* <Footer /> */}
      {/* <FooterSection /> */}
    </>
  )
}
