import React, { createContext, useReducer, useState } from 'react'
import AppReducer from './AppReducer'


  export const GlobalContext = createContext(false)
  
  export const GlobalProvider = ({ children }) => {
   
    
    const [isChangingTheme, setIsChaningTheme] = useState(false)

  return (
    <GlobalContext.Provider
      value={{
        isChangingTheme,
        setIsChaningTheme
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
